import React from "react"

// import StoreLayout from "../components/StoreLayout"
import Products from "../components/Products"
import SEO from "../components/SEO"
import { CatalogLayout } from "../components/CatalogLayout"
import HeroImg from "../images/bg.jpg"

const CatalogPage = () => (
  <CatalogLayout>
    <SEO
      title="Catalog"
      image={HeroImg}
      keywords={[
        `mint`,
        `fox`,
        `studio`,
        `kniting`,
        `handmade`,
        `knited lingerie`,
        `knited underware`,
        `knited swimsuite`,
        `accesories`,
        `Slava Kozachok`,
        `mintfox`,
      ]}
    />
    <Products title="Catalog"></Products>
  </CatalogLayout>
)

export default CatalogPage
