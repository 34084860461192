import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { motion } from "framer-motion"
import tw from "twin.macro"
import styled from "styled-components"
import { css } from "styled-components/macro" //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "./treact/misc/Buttons.js"
// import { ReactComponent as StarIcon } from "../images/star-icon.svg";

import { CartContext } from "./CartProvider"
// import css from "./ProductThumbnail.module.css"

const CardContainer = tw.div`my-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`
const Card = tw(
  motion.custom(Link)
)`bg-gray-200 rounded-xl block max-w-xs mx-auto sm:max-w-none sm:mx-0 hover:bg-primary-600`
const CardImageContainer = styled(BackgroundImage)`
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t-xl`}
`
// const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`
// const CardRating = styled.div`
//   ${tw`mr-1 text-sm font-bold flex items-end`}
//   svg {
//     ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
//   }
// `

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center rounded-t-xl`}
`
const CardButton = tw(PrimaryButtonBase)`text-sm`

// const CardReview = tw.div`font-medium text-xs text-gray-600`

const CardText = tw.div`p-4 text-gray-900`
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-100`
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600 group-hover:text-primary-200`
const CardPrice = tw.p`mt-4 text-xl font-bold group-hover:text-primary-100`

const ProductThumbnail = ({ product }) => {
  const soldOut = !product.prices[0].active
  return (
    <CardContainer key={product.id}>
      <Card
        className="group"
        to={`/buy/${product.slug}`}
        initial="rest"
        whileHover="hover"
        animate="rest"
      >
        <CardImageContainer
          fluid={product.localFiles[0].childImageSharp.fluid}
          tw="before:rounded-t-xl!"
        >
          {/* <CardRatingContainer>
              <CardRating>
                <StarIcon />
                {card.rating}
              </CardRating>
              <CardReview>({card.reviews})</CardReview>
            </CardRatingContainer> */}
          <CardHoverOverlay
            variants={{
              hover: {
                opacity: 1,
                height: "auto",
              },
              rest: {
                opacity: 0,
                height: 0,
              },
            }}
            transition={{ duration: 0.3 }}
          >
            <CardButton>See More</CardButton>
          </CardHoverOverlay>
        </CardImageContainer>
        <CardText>
          <CardTitle>{product.name}</CardTitle>
          <CardContent>{product.description}</CardContent>
          <CardPrice>
            {soldOut ? "Sold Out" : `$${product.prices[0].unit_amount / 100}`}
          </CardPrice>
        </CardText>
      </Card>
    </CardContainer>
  )
}

ProductThumbnail.propTypes = {
  product: PropTypes.object.isRequired,
}

export default ProductThumbnail
