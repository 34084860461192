import React, { useContext } from "react"
import { ProductsContext } from "./ProductsProvider"
import ProductThumbnail from "./ProductThumbnail"
import tw from "twin.macro"
import { motion } from "framer-motion"
import { Container, ContentXl } from "./treact/misc/Layouts.js"

const ProductContainer = tw(
  motion.div
)`flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`

const Items = ({ title }) => {
  const { listProducts } = useContext(ProductsContext)
  const products = listProducts()
  return (
    <div>
      <Container>
        <ContentXl>
          <h1 css={tw`text-3xl font-bold text-beige-700`}>{title}</h1>
          <ProductContainer>
            {products.map(product => (
              <ProductThumbnail key={product.id} product={product} />
            ))}
          </ProductContainer>
        </ContentXl>
      </Container>
    </div>
  )
}

export default Items
